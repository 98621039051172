import React, { useEffect, useState } from 'react';
import api from '../../store/Api/Intercepteur';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import translate from '../../store/Translation/Langue';
import { UseLanguage } from '../../store/User/language';

export const FeedBack = () => {
    const [feedback, setFeedBack] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 3;
    const language = UseLanguage.use.language()

    const fetchFeedBack = async () => {
        try {
            const response = await api.get(`/contacts`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setFeedBack(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchFeedBack();
    }, []);

    const totalPages = Math.ceil(feedback.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const startIndex = currentPage * itemsPerPage;
    const selectedFeedback = feedback.slice(startIndex, startIndex + itemsPerPage);

    return (
        <div className="w-full h-[500px] rounded-lg shadow-cardShadow bg-white py-10">
            <div className='w-full flex justify-between px-5 items-center'>
                <h2 className="text-3xl font-semibold text-center mb-6 text-gray-700">{translate(language, 'clientFeedBack')}</h2>
                <div className='flex gap-x-5'>
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 0}
                        className='flex justify-center items-center p-5 rounded-full bg-gray-100 text-primary hover:bg-primary/90 hover:text-white'
                    >
                        <FontAwesomeIcon icon={faArrowLeftLong} className='w-7 h-7' />
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages - 1}
                        className='flex justify-center items-center p-5 rounded-full bg-gray-100 text-primary hover:bg-primary/90 hover:text-white'
                    >
                        <FontAwesomeIcon icon={faArrowRightLong} className='w-7 h-7' />
                    </button>
                </div>
            </div>
            <div className='flex h-[300px] items-center justify-around'>
                {selectedFeedback.map((testimonial, index) => (
                    <div key={index} className="flex flex-col items-center justify-center text-center p-4">
                        <img
                            src={testimonial.photo ? testimonial.photo : '/image/profile.png'}
                            alt={testimonial.nom}
                            className="rounded-full w-24 h-24 mb-4 border-4 border-gray-700"
                        />
                        <h3 className="text-xl font-bold text-primary">{testimonial.nom}</h3>
                        <p className="text-blue-400 text-sm mb-2">{testimonial.email}</p>
                        <p className="text-gray-400 mb-4">{testimonial.message}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
