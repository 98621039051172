import axios from 'axios';

// export const baseURL = 'http://192.168.1.139:8001/api'
// export const baseUrlImage = 'http://192.168.1.139:8001'
export const baseURL = 'http://91.134.91.43:8000/api'
export const baseUrlImage = "http://91.134.91.43:8000"

const api = axios.create({
    baseURL: `${baseURL}`,
});

export default api
