import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppStore } from "../../../store/User/user"
import translate from "../../../store/Translation/Langue"
import { UseLanguage } from "../../../store/User/language"

export default function SelectionParts({ chapitre, isSuivi, items, select, idchapitre, tousCours }) {
    const [showContent, setShowContent] = useState(false)
    const navigation = useNavigate()
    const [isFinished, setisFinished] = useState(false)
    const type = useAppStore.use.type()
    const language = UseLanguage.use.language()

    const selection = () => {
        setShowContent(!showContent)
    }

    const ViewChapitre = () => {
        console.log("id", idchapitre)
        navigation("/Chapitre/view-chapitre", { state: { items, idchapitre } })
    }

    const passerExamen = () => {
        const id = select.id
        navigation('/examen/passer-examen', { state: { chapitre, id } })
    }

    const ViewChapitreProf = () => {
        navigation("/Chapitre/view-chapitre", { state: { items, idchapitre } })
    }

    return (
        <div className="w-full">
            {type === "enseignant" ? (
                <div className="w-full">
                    <button onClick={ViewChapitreProf} className={`flex justify-between w-full border rounded-lg mt-3 p-2 hover:shadow-cardShadow`}>
                        <div onClick={ViewChapitre} className={`w-full flex justify-start items-center`}>
                            <div className="bg-secondary rounded-full mr-2 w-10 h-10 text-center flex flex-col justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid">
                                    <rect x="3" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="14" width="7" height="7"></rect>
                                    <rect x="3" y="14" width="7" height="7"></rect>
                                </svg>
                            </div>
                            <div className="flex flex-col justify-center">
                                <p className="text-gray-900 font-bold">{chapitre}</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faChevronDown} className={`w-4 h-4 text-gray-600 ${showContent ? 'transition-all duration-500 linear rotate-180' : "transition-all duration-500 linear"}`} />
                        </div>
                    </button>
                </div>
            ) : (
                <div className="w-full">
                    <button className={`flex justify-between w-full border rounded-lg mt-3 p-2 hover:shadow-cardShadow`}>
                        <div onClick={isSuivi ? ViewChapitre : selection} className={`w-full flex justify-start items-center`}>
                            <div className="bg-secondary rounded-full mr-2 w-10 h-10 text-center flex flex-col justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid">
                                    <rect x="3" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="14" width="7" height="7"></rect>
                                    <rect x="3" y="14" width="7" height="7"></rect>
                                </svg>
                            </div>
                            <div className="flex flex-col justify-center">
                                <p className="text-gray-900 font-bold">{chapitre}</p>
                                {isSuivi && (
                                    <p className={`text-[10px] text-black/50 text-left`}>{isFinished ? translate(language, "finished") : translate(language, "ongoing")}</p>
                                )}
                            </div>
                        </div>
                        {isSuivi && (
                            <div className="flex items-center">
                                {
                                    isFinished ? (
                                        <div className="flex justify-around items-center text-black/80">
                                            {/* <button onClick={ViewChapitre} className="mr-2">Voir</button> */}
                                            <button className="p-2 py-1 border rounded">
                                                <FontAwesomeIcon icon={faCheck} className="text-primary" />
                                            </button>
                                        </div>
                                    ) : (
                                        <button onClick={passerExamen} className="flex justify-center items-center h-full w-40">
                                            <p>{translate(language, "PassExam")}</p>
                                        </button>
                                    )
                                }
                            </div>
                        )}
                    </button>
                    <div className={`${showContent ? `h-full` : "h-0"} overflow-y-auto transition-all duration-200 ease-linear`}>
                    </div>
                </div>
            )}
        </div>
    )
}