import React, { useState } from "react"
import { OSInput } from "../../Components/Input/OSInput";
import { TextAreaInput } from "../../Components/Input/TextareaInput";
import translate from "../../store/Translation/Langue";
import { UseLanguage } from "../../store/User/language";
import api from "../../store/Api/Intercepteur";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

export const Contact = () => {
    const language = UseLanguage.use.language()
    const [nom, setNom] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const navigation = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            await api.post(`/contact`,
                {
                    nom: nom,
                    email: email,
                    telephone: phone,
                    sujet: subject,
                    message: message
                }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            toast.success("Votre feedback a été envoyé, merci de nous avoir contacter!", {
                onClose: () => {
                    navigation('/')
                }
            });
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="max-w-6xl flex flex-col items-center mx-auto mb-24 mt-28 font-[sans-serif] text-[#011c2b]">
            <div className="grid w-5/6 lg:grid-cols-3 items-center justify-around gap-4 p-2 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-lg mt-8">
                <div className="rounded-lg p-6 max-lg:text-center">
                    <h2 className="text-2xl font-bold text-black/80">{translate(language, "infoContact")}</h2>
                    <p className="text-sm text-gray-400 mt-3">{translate(language, "doyouHave")}</p>
                    <ul className="mt-16 space-y-10">
                        <li className="flex items-center max-lg:justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#22c55e'
                                viewBox="0 0 479.058 479.058">
                                <path
                                    d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                                    data-original="#000000" />
                            </svg>
                            <a href="/" className="text-black/80 text-sm ml-3">
                                <strong>santatra00@gmail.com</strong>
                            </a>
                        </li>
                        <li className="flex items-center max-lg:justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#22c55e' viewBox="0 0 482.6 482.6">
                                <path d="M98.339 320.8c47.6 56.9 104.9 101.7 170.3 133.4 24.9 11.8 58.2 25.8 95.3 28.2 2.3.1 4.5.2 6.8.2 24.9 0 44.9-8.6 61.2-26.3.1-.1.3-.3.4-.5 5.8-7 12.4-13.3 19.3-20 4.7-4.5 9.5-9.2 14.1-14 21.3-22.2 21.3-50.4-.2-71.9l-60.1-60.1c-10.2-10.6-22.4-16.2-35.2-16.2-12.8 0-25.1 5.6-35.6 16.1l-35.8 35.8c-3.3-1.9-6.7-3.6-9.9-5.2-4-2-7.7-3.9-11-6-32.6-20.7-62.2-47.7-90.5-82.4-14.3-18.1-23.9-33.3-30.6-48.8 9.4-8.5 18.2-17.4 26.7-26.1 3-3.1 6.1-6.2 9.2-9.3 10.8-10.8 16.6-23.3 16.6-36s-5.7-25.2-16.6-36l-29.8-29.8c-3.5-3.5-6.8-6.9-10.2-10.4-6.6-6.8-13.5-13.8-20.3-20.1-10.3-10.1-22.4-15.4-35.2-15.4-12.7 0-24.9 5.3-35.6 15.5l-37.4 37.4c-13.6 13.6-21.3 30.1-22.9 49.2-1.9 23.9 2.5 49.3 13.9 80 17.5 47.5 43.9 91.6 83.1 138.7zm-72.6-216.6c1.2-13.3 6.3-24.4 15.9-34l37.2-37.2c5.8-5.6 12.2-8.5 18.4-8.5 6.1 0 12.3 2.9 18 8.7 6.7 6.2 13 12.7 19.8 19.6 3.4 3.5 6.9 7 10.4 10.6l29.8 29.8c6.2 6.2 9.4 12.5 9.4 18.7s-3.2 12.5-9.4 18.7c-3.1 3.1-6.2 6.3-9.3 9.4-9.3 9.4-18 18.3-27.6 26.8l-.5.5c-8.3 8.3-7 16.2-5 22.2.1.3.2.5.3.8 7.7 18.5 18.4 36.1 35.1 57.1 30 37 61.6 65.7 96.4 87.8 4.3 2.8 8.9 5 13.2 7.2 4 2 7.7 3.9 11 6 .4.2.7.4 1.1.6 3.3 1.7 6.5 2.5 9.7 2.5 8 0 13.2-5.1 14.9-6.8l37.4-37.4c5.8-5.8 12.1-8.9 18.3-8.9 7.6 0 13.8 4.7 17.7 8.9l60.3 60.2c12 12 11.9 25-.3 37.7-4.2 4.5-8.6 8.8-13.3 13.3-7 6.8-14.3 13.8-20.9 21.7-11.5 12.4-25.2 18.2-42.9 18.2-1.7 0-3.5-.1-5.2-.2-32.8-2.1-63.3-14.9-86.2-25.8-62.2-30.1-116.8-72.8-162.1-127-37.3-44.9-62.4-86.7-79-131.5-10.3-27.5-14.2-49.6-12.6-69.7z" data-original="#000000"></path>
                            </svg>
                            <a href="/" className="text-black/80 text-sm ml-3">
                                <strong>+261 34 61 008 76</strong>
                            </a>
                        </li>
                        <li className="flex items-center max-lg:justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#22c55e' viewBox="0 0 512 512">
                                <path d="M374.6 45.1h-237c-27.4 0-49.8 22.4-49.8 49.8v322.3c0 27.4 22.4 49.8 49.8 49.8h237c27.4 0 49.8-22.4 49.8-49.8V94.9c-.1-27.4-22.5-49.8-49.8-49.8zm20.7 372.1c0 11.4-9.3 20.7-20.7 20.7h-237c-11.4 0-20.7-9.3-20.7-20.7V94.9c0-11.4 9.3-20.7 20.7-20.7h237c11.4 0 20.7 9.3 20.7 20.7v322.3z" data-original="#000000"></path>
                                <path d="M256 121.9c-50.6 0-91.7 41.1-91.7 91.7s41.1 91.7 91.7 91.7 91.7-41.1 91.7-91.7-41.1-91.7-91.7-91.7zm0 153.5c-34.1 0-61.7-27.7-61.7-61.7s27.7-61.7 61.7-61.7 61.7 27.7 61.7 61.7-27.6 61.7-61.7 61.7zm99.7-151.1c-11.3 0-20.5 9.2-20.5 20.5s9.2 20.5 20.5 20.5 20.5-9.2 20.5-20.5-9.2-20.5-20.5-20.5z" data-original="#000000"></path>
                            </svg>
                            <a href="/a" className="text-black/80 text-sm ml-3">
                                <strong>@hay</strong>
                            </a>
                        </li>
                    </ul>
                </div>
                <form onSubmit={onSubmit} className="lg:col-span-2 bg-white p-6 rounded-lg space-y-4 max-lg:shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)]">
                    <div className="flex justify-between gap-4 max-lg:flex-col max-lg:space-y-4 max-lg:w-full">
                        <div className="w-full">
                            <OSInput defaultValue={nom} state={(e) => setNom(e.target.value)} placeholder={translate(language, 'name')} id="nom" />
                        </div>
                        <div className="w-full">
                            <OSInput defaultValue={phone} state={(e) => setPhone(e.target.value)} placeholder={translate(language, 'phone')} id="phone" />
                        </div>
                    </div>
                    <div>
                        <OSInput defaultValue={email} state={(e) => setEmail(e.target.value)} placeholder="Email" id="email" />
                    </div>
                    <div>
                        <OSInput defaultValue={subject} state={(e) => setSubject(e.target.value)} placeholder={translate(language, "subject")} id={translate(language, "subject")} />
                    </div>
                    <div>
                        <TextAreaInput defaultValue={message} onChange={(e) => setMessage(e.target.value)} label={translate(language, "message")} id="message" />
                    </div>
                    <div>
                        <button className="w-full p-3 bg-primary text-white rounded-md hover:bg-thirdly">{translate(language, "sendMessage")}</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}
