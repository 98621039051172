import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SelectionParts from "../../Components/Cards/Visiteur/SelectionPart";
import { CoursCardVisiteur } from "../../Components/Cards/Visiteur/CoursCard"
import translate from "../../store/Translation/Langue";
import { UseLanguage } from "../../store/User/language";
import * as animationData from '../../Lottie/Certificate.json';
import Lottie from "react-lottie";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import api from "../../store/Api/Intercepteur";
import Modal from "react-modal";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import { useAppStore } from "../../store/User/user";

export const DetailCours = () => {
    const language = UseLanguage.use.language()
    const location = useLocation()
    const selectedItem = location.state.selectedItem
    const [isSuivi, setisSuivi] = useState(location.state.isSuivi || "")
    const [items, setItems] = useState(0)
    const [finishedCourse, setFinishedCourse] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const modalRef = useRef()
    const [haveCertificate, setHaveCertificate] = useState(false)
    const currentDateTime = dayjs();
    const [selectedDate, setSelectedDate] = useState(null);
    const [reservation, setResvation] = useState([])
    const [code, setCode] = useState('')
    const user = useAppStore.use.user()
    const userInfo = user.info

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    const handleNotificationClose = () => {
        setShowModal(false);
    };

    const trigerSuivi = () => {
        setisSuivi(true)
    }

    const getCode = async () => {
        try {
            const response = await api.get(`/certificate/${userInfo.id}/${selectedItem.id}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            )
            setCode(() => {
                return response.data.code
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => { getCode() }, [])

    const generateUniqueId = () => {
        return Math.random().toString(36).substr(2, 5);
    };

    const handleGetCertificate = () => {
        setShowModal(true)
    };

    const saveCertificateLinkToDatabase = (idUnique, userId) => {
        api.post('/certificates',
            {
                code: idUnique,
                idUser: userId,
                idCours: selectedItem.id
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
        )
            .then(response => {
                console.log('Certificat sauvegardé');
            })
            .catch(error => {
                console.error('Erreur lors de la sauvegarde du certificat', error);
            });
    };

    const toastShowSuccess = (message) => {
        toast.success(message)
    }

    const toastShowError = (message) => {
        toast.error(message)
    }

    const BuyCertificateClicked = async () => {
        const idUnique = generateUniqueId();
        await saveCertificateLinkToDatabase(idUnique, userInfo.id);
        const url = `/certificat/${idUnique}`;
        window.open(url, '_blank');
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleNotificationClose()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const handleHaveCertificate = () => {
        const url = `/certificat/${code}`;
        window.open(url, '_blank');
    }

    const HandleReserverRendezvous = async () => {
        try {
            await api.post('/reservations',
                { date: selectedDate, id_prof: selectedItem.user.id },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            )
            toast.success('Rendez-vous resèrvé!')
        } catch (error) {
            console.log(error)
        }
    }

    const fetchReservation = async () => {
        try {
            const response = await api.get('/reservations', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            setResvation(() => {
                // console.log(response.data)
                return response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
    const shouldDisableTime = (timeValue, clockType) => {
        if (clockType === 'hours' || clockType === 'minutes') {
            return false;
        }
        if (clockType === 'days') {
            const reservedDates = reservation.map(rendezvous => dayjs(rendezvous.date).startOf('day').format('YYYY-MM-DD'));
            return reservedDates.includes(timeValue.startOf('day').format('YYYY-MM-DD'));
        }
        return false;
    };

    useEffect(() => {
        fetchReservation();
    }, []);


    const renderContent = (items, description) => {
        switch (items) {
            case 0:
                return (
                    <div className="h-full">
                        <div className="my-5 w-full">
                            <p className="text-blue-950 font-bold text-xl">{translate(language, "yllLearn")}</p>
                            <div dangerouslySetInnerHTML={{ __html: selectedItem.details }} className="text-black/80" />
                        </div>
                        <div className="my-5">
                            <p className="text-blue-950 font-bold text-xl">{translate(language, "aboutCourse")}</p>
                            <p className="text-black/80">{description}</p>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div className="flex flex-col items-center mt-3 w-full">
                        {selectedItem.chapitres.map(c => (
                            <SelectionParts key={c.id} chapitre={c.titre} part={c.part} isSuivi={isSuivi} select={c} items={selectedItem.chapitres} idchapitre={c.id} />
                        ))}
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <div className="relative flex flex-col items-center h-full min-h-[100vh] mt-14 w-full">
            <div
                className="absolute inset-0 bg-cover bg-center filter h-60 w-full"
                style={{
                    backgroundImage: `url('https://media.istockphoto.com/id/1663317152/fr/photo/jeune-femme-caucasienne-utilisant-un-ordinateur-portable-tout-en-travaillant-%C3%A0-domicile.webp?b=1&s=170667a&w=0&k=20&c=N58-jNPm3pq-ypXW3RvUgRww-jPsgRCTUo_McM5_dDo=')`,
                    backgroundSize: "cover",
                }}
            >
                <div className="w-full h-full bg-black/70"></div>
            </div>
            <div className="relative top-0 w-5/6 h-full flex justify-around bg-transparent rounded-lg p-5 pt-4 max-lg:flex-col max-sm:w-full">
                <div className="w-1/2 ml-4 flex flex-col items-center max-lg:w-full max-lg:ml-1">
                    <div className="text-gray-200 flex flex-col items-start w-full">
                        <p className="text-white text-3xl font-bold text-left h-16">{selectedItem.nom}</p>
                        <span className="text-gray-200 text-sm my-6">
                            <span>{translate(language, "in")} </span>
                            <Link to="/matiere/view-matiere" className="hover:underline decoration-gray-200">{selectedItem.categorie}</Link>
                        </span>
                        <p className="">{translate(language, "createdBy")}
                            <Link to={`/otherProfile?user=${selectedItem.id_user}`} className="text-right hover:underline ml-1">{selectedItem.enseignant}</Link>
                        </p>
                    </div>
                    <div className="flex justify-around w-full bg-[#1f3b64] mt-11 rounded-lg">
                        <button onClick={() => setItems(0)} className="h-full">
                            <p className="text-white font-bold p-2">Information</p>
                            <p className={`${items === 0 ? 'rounded-md bg-secondary w-full h-1' : ""}`}></p>
                        </button>
                        <button onClick={() => setItems(1)}>
                            <p className="text-white font-bold p-2 ">{isSuivi ? translate(language, 'lesson') : `Part (${selectedItem.chapitres.length})`}</p>
                            <p className={`${items === 1 ? 'rounded-md bg-secondary w-full h-1' : ""}`}></p>
                        </button>
                    </div>
                    <div className="w-full flex flex-col items-center">{renderContent(items, selectedItem.description)}</div>
                </div>
                <div className="mt-0.5">
                    <div className={`${isSuivi ? 'w-80 bg-white shadow-cardShadow rounded-lg px-4 pb-20 mt-10 flex flex-col items-center' : ''}`}>
                        {
                            isSuivi ? (
                                <div className="w-full flex justify-center flex-col">
                                    <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
                                    {
                                        finishedCourse ?
                                            code ? (
                                                <button onClick={handleHaveCertificate} className="py-2 px-4 rounded-lg bg-primary ml-3 font-bold text-white">Get certificate</button>
                                            ) : (
                                                <button onClick={handleGetCertificate} className="py-2 px-4 rounded-lg bg-primary ml-3 font-bold text-white">Get certificate</button>
                                            ) : (
                                                <p className="text-center text-black/80 font-bold w-40">Finish all the exam to get Certificate</p>
                                            )
                                    }
                                    <div className="ml-2 mt-5">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <DateTimePicker
                                                    label="Réserver un rendez-vous"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    minDateTime={currentDateTime}
                                                    disablePast
                                                    shouldDisableTime={shouldDisableTime}
                                                    className="text-primary focus:ring-primary focus:border-primary"
                                                    onAccept={HandleReserverRendezvous}
                                                    slotProps={{
                                                        textField: {
                                                            InputLabelProps: {
                                                                className: 'text-primary',
                                                            },
                                                            InputProps: {
                                                                className: 'border-primary focus:border-primary focus:ring-primary',
                                                            },
                                                        },
                                                        actionBar: {
                                                            actions: ['cancel', 'accept'],
                                                            acceptButtonLabel: 'Réserver',
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            ) : (
                                <CoursCardVisiteur image="http://lms.rocket-soft.org/store/1015/new_live_system.jpg" cout={selectedItem.cout} id_cours={selectedItem.id} trigersuivi={trigerSuivi} sendResponseSuccess={toastShowSuccess} sendResponseError={toastShowError} />
                            )
                        }
                    </div>
                </div>
            </div>
            {
                showModal && (
                    <Modal
                        isOpen={showModal}
                        onRequestClose={handleNotificationClose}
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                    >
                        <div
                            ref={modalRef}
                            className={`bg-white relative flex flex-col rounded-lg shadow-lg w-96 transform transition-all duration-300 ease-in-out`}
                        >
                            <div className="flex justify-between items-center p-5 border-b">
                                <h3 className="text-lg font-medium">Paiement pour avoir un certificat</h3>
                                <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray                            -700 transition duration-150 ease-in-out focus:outline-none">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="flex w-full mt-5 flex-col px-6 py-2 items-start rounded-md bg-default-background shadow-sm">
                                <p className="text-black/80 font-bold text-center w-full">
                                    Get certificate for <span className="text-red-500">25 Credits</span>
                                </p>
                                <div className="flex justify-around w-full py-5 mt-4">
                                    <button
                                        onClick={handleNotificationClose}
                                        className="w-40 text-center cursor-pointer px-4 py-2 font-bold border border-primary rounded text-[#020617] text-opacity-90 border-opacity-90"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={BuyCertificateClicked}
                                        className="text-nowrap text-center w-40 bg-primary hover:bg-opacity-90 cursor-pointer rounded text-white py-2 font-bold"
                                    >
                                        Buy certificate
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
            <ToastContainer />
        </div >
    )
}